$(document).ready(function() {
  $("#itemGallery").lightGallery({
    selector: '.product-item-gallery',

  });
 

  
  $('body ').on("click",'.btn_open_catalog_modal',function(e){
    // e.preventDefault();
    // var self = $(this);
    // var item_name = self.data('item-name');
    // var item_category = self.data('item-category');
    // var modal = $('#catalog_modal'); 
// alert('d');
    // modal.find('.inp_3017').val(item_cs);
    // modal.find('.inp_3033').val(item_name);
    // modal.find('.inp_3169').val(item_category);
    // modal.modal('show');

      var self = $(this);
      var item_cs = self.data('id');
    
    console.log(item_cs); 
 
    $("#namofimage").val(item_cs);
    $("#nameimage").text(item_cs); 

   
    $("#myModalc").modal();
    // e.preventDefault();
    // e.stopPropagation(); 
});

});
