$(document).ready(function() {

	var dirRtlFlag = false;
	if ($("html").attr('dir') == 'rtl') {
		dirRtlFlag = true;
	}
	$(".other-photo-slider__slider").lightGallery(); 
	$('.other-photo-slider__slider').slick({
		dots: false,
		infinite: true,
		speed: 2000,
		slidesToShow: 10,
		slidesToScroll: 1,
		arrows: true,
		nextArrow: '.slider-arrows__left',
		prevArrow: '.slider-arrows__right',
		autoplay: true,
		autoplaySpeed: 1000,
		rtl: dirRtlFlag,
		// centerMode: true,
		variableWidth: true,
		responsive: [{
			breakpoint: 415,
			settings: {
				slidesToShow: 1,
				centerMode: false,
			}
		}]
	});

})