$(document).ready(function() {

	function toggleSideForm() {
		$('.side-form-container').toggleClass('side-form-container_open');
		$('.side-btn').toggleClass('side-btn_close');

		if ($('.hide-text_show').length) {
			$('.hide-text').removeClass('hide-text_show');
		} else {
			setTimeout(function() {
				$('.hide-text').addClass('hide-text_show');
			}, 300);
		}
	}

	$('.side-form__button').click(toggleSideForm);

	$('body').keyup(function(e) {
		if ($('.side-form-container_open').length && e.which == 27) {
			toggleSideForm();
		}
	});

});